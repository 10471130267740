.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 5%;
}

.button, .buttonPrevious {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
    background-color: var(--brandButtonBackground);
    border-radius: 20px;
    padding: 8px 15px 8px 15px;
    color: #FFFFFF;
    font-family: var(--brandTitleFontFamily);
    font-style: var(--brandTitleFontStyle);
    align-self: center;
    cursor: pointer;
    transition: 0.3s;
}

.button:hover {
    background-color: var(--brandButtonBackgroundHovered);
}

.buttonPrevious {
    background-color: #FFFFFF;
    border: 1px solid var(--brandPurple);
    color: var(--brandPurple);
}

.buttonPrevious:hover {
    background-color: rgba(74, 29, 156, 0.04);
}

.arrowLeft, .arrowRight {
    font-size: 22px;
}

.arrowLeft {
    color: var(--brandPurple);
    margin-right: 5px;
}

.arrowRight {
    margin-left: 5px;
    align-self: center;
}