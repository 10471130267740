.container {
    width: 90%;
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 5%;
    line-height: 24px;
}

.table {
    width: 100%;
    font-family: var(--brandBodyFontFamily) !important;
    table-layout: fixed;
    width: 100%;
    overflow-x: scroll;
    min-width: 920px;
}

.table td {
    padding: 20px 20px 20px 0px;
    vertical-align: top;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
   width: 20% !important;
}

.table tr {
    width: 100%;
}

.row {
    border: 1px solid green;
    border-top: 1px solid (0, 0, 0, 0.04);
    border-bottom: 1px solid (0, 0, 0, 0.04);
    
}

.tableTitle, .tableTopText {
    width: 30%;
    font-size: 16px;
    font-family: var(--brandBodyFontFamily) !important;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
}

.tableTopText {
    border-top: 2px solid var(--brandPurple);
    padding-top: 2% !important;
    padding-bottom: 2% !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.tableResult {
    font-size: 16px;
    font-family: var(--brandTitleFontFamily) !important;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
}

.tableResultNotBolded {
    font-size: 16px;
    font-family: var(--brandBodyFontFamily) !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.87);
}

.mainTitle {
    font-size: 20px;
    font-family: var(--brandTitleFontFamily) !important;
    font-weight: 600;
    color: var(--brandPurple);
    vertical-align: bottom !important;
    padding-bottom: 1% !important;
    background-color: #FAFAFB;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.topRow {
    border-bottom: 2px solid var(--brandPurple);
}

.bottomRow {
    border-bottom: none !important;
}

@media screen and (max-width: 1060px) {
    .container {
        overflow-x: scroll;
    }

    .tableTopText {
        text-align: left !important;
    }
}