.container {
    width: 70%;
    margin: 0 auto;
}

.column {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
}
/*
.titleText::after {
    content: url('../../media/warning.svg');
    content: ;
    display: inline-block;
}*/

.row, .assesseeRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 5%;
}

.assesseeRow {
    margin-top: 2%;
    justify-content:flex-start
}

@media screen and (max-width: 920px) {
    .row {
        flex-direction: column !important;
    }

    .fieldContainerRow {
        flex-direction: column !important;
        width: 100% !important;
    }

    .fieldContainerColumn {
        width: 100% !important;
    }

    .textFieldStep1 {
        width: 100% !important;
    }

    .text {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .textColumn {
        width: 100% !important;
        padding-right: 0px !important;
    }

    .textDesc {
        margin-bottom: 10px;
    }

    .textFieldStep2 {
        margin-left: 0px !important;
        font-size: 12px !important ;
    }

    .textFullWidth {
        margin-bottom: 10px;
    }
}

.text, .textFullWidth {
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    width: 30%;
    padding-right: 5%;
    margin-right: 0;
    font-size: 20px;
    color: var(--brandBodyFontColor);
    font-family: var(--brandTitleFontFamily);
    font-style: var(--brandTitleFontStyle);
    align-self: baseline;
}

.textFullWidth {
    width: 100%;
}

.fieldContainerRow {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fieldContainerColumn {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.textFieldStep1 {
    width: 100% !important;
}

.textFieldStep2 {
    width: 100% !important;
}

.textFieldStep1Column {
    width: 98% !important;

}

.checkboxContainer, .labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-family: var(--brandBodyFontFamily);
}

.labelContainer div {
    margin-right: 5px;
}

.redStar {
    margin-left: 5px;
    color: red;
}

.textColumn {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding-right: 3%;
}

.textDesc {
    margin-top: 2%;
    font-size: 16px;
    font-family: var(--brandBodyFontFamily);
    color: rgba(0, 0, 0, 0.6);
}

.step3CheckboxMargin {
    margin-top: 3%;
}

.counterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 32px;
    width: 92px;
    border-radius: 8px;
    background-color: rgba(74, 29, 156, 0.08);
}

.counterDigitalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 1px solid var(--brandPurple);
    border-radius: 8px;
    height: 28px;
    width: 28px;
}

.counterDigital {
    font-size: 14px;
    font-weight: 700;
    font-family: var(--brandBodyFontFamily);
    cursor: default;
}

.counterButton {
    display: flex;
    font-family: var(--brandBodyFontFamily);
    font-weight: 700;
    cursor: pointer;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
}

.bottomLine {
    margin-top: 2%;
    margin-bottom: 2%;
    border-bottom: 2px solid rgba(0, 0, 0, 0.04);
}

.topLine {
    margin-top: 1%;
    margin-bottom: 3%;
    border-bottom: 2px solid var(--brandPurple);
}

.radioGroup {
    font-family: var(--brandBodyFontFamily) !important;
    margin-top: 1% !important;
    display: flex !important;
    flex-direction: row !important;
}

.textFieldStep4 {
    margin-top: 3% !important;
    width: 100% !important;
}

.table {
    width: 100%;
    line-height: 24px;
}

.table td {
    padding: 10px 20px 10px 0px;
    vertical-align: top;
    text-align: left;
}

.tableBoldTitle {
    width: 30%;
    font-size: 20px;
    font-family: var(--brandTitleFontFamily) !important;
    font-weight: 600;
}

.tableTitle {
    width: 30%;
    font-size: 16px;
    font-family: var(--brandBodyFontFamily) !important;
    color: rgba(0, 0, 0, 0.6);
}

.tableResult {
    font-size: 16px;
    font-family: var(--brandTitleFontFamily) !important;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
}

.tableResultNotBolded {
    font-size: 16px;
    font-family: var(--brandBodyFontFamily) !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.87);
}

.extraContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.error {
    border: 1px solid #d32f2f;
    border-radius: 3px;
}

.wrapper {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 480px) {
    .labelContainer {
        font-size: 1rem !important;
    }

    .container {
        width: 90% !important;
    }

    .step3CheckboxMargin {
        margin-bottom: 6%;
    }
}