.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    align-items: baseline;
    margin-top: 5%;
    width: 70%;
}

.title {
    font-size: var(--brandTitleFontSize);
    color: var(--brandTitleFontColor);
    font-family: var(--brandTitleFontFamily);
    font-weight: var(--brandTitleFontWeight);
    align-self: center;
}

.chosenPackageContainer {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.chosenPackage, .selectedPackageTitle {
    font-family: var(--brandTitleFontFamily);
    font-size: 20px;
}

.chosenPackage {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    margin-right: 5px;
}

.selectedPackageTitle {
    display: flex;
    flex-direction: row;
    font-weight: 700
}

.priceTag {
    margin-left: 5px;
}


.underText {
    font-family: var(--brandBodyFontFamily);
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 14px;
    align-self: center;
    margin-top: 5px;
    margin-left:auto;
}

.row {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 920px) {
    .container {
        flex-direction: column;
    }

    .chosenPackageContainer {
        margin-top: 20px;
        margin-right: auto;
        flex-direction: column !important;
    }

    .selectedPackageTitle {
        margin-top: 5px;
    }

    .title {
        margin-right: auto !important;
    }

    .row {
        align-items: baseline;
    }
}

@media screen and (max-width: 480px) {
    .container {
        width: 90% !important;
        margin-bottom: 8%;
    }


}