.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    line-height: 24px;
    padding-bottom: 5%;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
    background-color: var(--brandButtonBackground);
    border-radius: 20px;
    padding: 8px 15px 8px 15px;
    color: #FFFFFF;
    font-family: var(--brandTitleFontFamily);
    font-weight: 700;
    font-size: 14px;
    align-self: center;
    cursor: pointer;
    transition: 0.3s;
}

.button:hover {
    background-color: var(--brandButtonBackgroundHovered);
}

.checkMark {
    width: 89px;
    height: 89px;
    color: var(--brandPurple);
}

.thankYouText {
    font-family: var(--brandTitleFontFamily);
    font-weight: 600;
    font-size: 36px;
    color: var(--brandPurple);
    margin-top: 3%;
}

.weWillContactText {
    font-family: var(--brandTitleFontFamily);
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 2%;
}

.nextStepsText {
    font-family: var(--brandTitleFontFamily);
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 5%;
}

.eezyAlsoOffersText {
    font-family: var(--brandTitleFontFamily);
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10%;
    align-self: flex-start;
}

.offersContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%;
}

@media screen and (max-width: 480px) {
    .container {
        width: 90%;
    }
}

@media screen and (max-width: 920px) {
    .offersContainer {
        flex-direction: column;
        margin-bottom: 5%;
    }

    .offerItem {
        padding: 10px !important;
        margin-top: 20px;
        margin-left: 2%;
    }

    .floatRight {
        align-self: center !important;
    }
}

.offerItem {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 2%;
    box-shadow: rgba(16, 24, 40, 0.1) 0px 4px 12px, rgba(129, 80, 190, 0.1) 0px 4px 12px;
}

.offerItem:not(:first-of-type){
    margin-left: 2%;
}

.offerItemTitle {
    font-family: var(--brandTitleFontFamily);
    font-weight: 600;
    font-size: 18px;
}

.offerItemText {
    font-family: var(--brandBodyFontFamily);
    font-weight: 400;
    font-size: 14px;
    margin-top: 4%;
}

.purple {
    background-color: #E7D2F9;
}

.green {
    background-color: #D2F390;
}

.orange {
    background-color: #FFC066;
}

.arrowRight {
    margin-left: 5px;
    align-self: center;
}

.floatRight {
    align-self: flex-end;
}