/* Root colors are exported to index.css */
:root {
    --brandMain: #ED1B24;
    --brandWhite: #FFFFFF;
    --brandFormBackground: #F9F9F9;
    --brandShadow: rgba(0, 0, 0, 0.01) 0px 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px 0px 4px 0px;
    --brandFormCardGap: 24px;

    --brandTitleFontSize: 36px;
    --brandTitleFontColor: #4A1D9C;
    --brandTitleFontFamily: 'Quicksand';
    --brandTitleFontWeight: 600;

    --brandPurple: #4A1D9C;
    
    --brandButtonBackground: #4A1D9C;
    --brandButtonBackgroundHovered: #1d095a;

    --brandBodyFontSize: 16px;
    --brandBodyFontColor: rgba(0,0,0,0.87);
    --brandBodyFontFamily: 'Source Sans Pro';
    --brandBodyFontWeight: 400;
}