@font-face {
    font-family: 'Euclid';
    src: url('/src/fonts/euclid-circular-a-cufonfonts/Euclid\ Circular\ A\ Regular.ttf');
    font-weight: normal;
}

.container {
    background-color: #351f65;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #FFFFFF;
    padding: 4rem 5rem 2rem 5rem;
    font-family: 'Euclid';
}

.middleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10%;
}

.link1, .link2, .link3, .link4, .link1:visited, .link2:visited, .link3:visited, .link4:visited  {
    color: #FFFFFF;
}

.link1, .link2, .link3, .link4  {
    text-decoration: none;
}

.link2, .link3, .link4 {
    font-size: 0.8rem;
}

.link2 {
    padding: 0px 5px 0px 0px;
    border-right: 1px solid #FFFFFF;
}

.link3 {
   border-right: 1px solid #FFFFFF;
   padding: 0px 5px 0px 5px;
}

.link4 {
    padding: 0px 5px 0px 5px; 
}

.copyrightText {
    font-size: 0.8rem;
    margin-top: 1.5rem;
}

.yTunnus {
    font-size: 1.05rem;
    margin-bottom: 0.5rem;
}

.faceIcon {
    margin-right: 10px;
}

.middleBottom {
    margin-top: 1rem;
}

.faceIcon:hover, .linkedIcon:hover {
    cursor: pointer;
}

@media screen and (max-width: 930px) {
    .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .middleContainer {
        padding-right: 0;
        margin-top: 5%;
    }
}

@media screen and (max-width: 600px) {

    .middleBottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .link2, .link3, .link4 {
        border-right: none;
    }
}