.container {
    width: 79%;
    margin: 0 auto;
    margin-top: 5%;
}

.processContainer {
    margin-top: 20px;
}

.top {
    position: relative;
    left: -7%;
}

.title {
    font-size: 42px !important;
}

.title,
.title2 {
    font-size: var(--brandTitleFontSize);
    color: var(--brandTitleFontColor);
    font-family: var(--brandTitleFontFamily);
    font-weight: var(--brandTitleFontWeight);
}

.body {
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: 20px;
    color: var(--brandBodyFontColor);
    font-family: var(--brandBodyFontFamily);
    font-style: var(--brandBodyFontStyle);
    line-height: 24px;
}

.stepContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}

.step1,
.step2,
.step3,
.step4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--brandBodyFontSize);
    color: var(--brandPurple);
    font-family: var(--brandBodyFontFamily);
    font-weight: var(--brandBodyFontWeight);
    width: 25%;
    border-top: 2px solid #7A4CC3;
    border-bottom: 2px solid #7A4CC3;
}

.step1 {
    background-color: rgba(74, 29, 156, 0.04);
    border-left: 2px solid #7A4CC3;
    border-top-left-radius: 69px;
    border-bottom-left-radius: 69px;
}

.step2 {
    background-color: rgba(74, 29, 156, 0.08);
}

.step3 {
    background-color: rgba(74, 29, 156, 0.12);

}

.step4 {
    background-color: rgba(74, 29, 156, 0.3);
    border-bottom-right-radius: 69px;
    border-top-right-radius: 69px;
    border-right: 2px solid #7A4CC3;
}

.stepText {
    margin-top: 10px;
    margin-bottom: 10px;
}

.topBox1,
.topBox2,
.topBox3,
.bottomBox1,
.bottomBox2,
.bottomBox3,
.bottomBox4,
.leftBox1,
.rightBox1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topBox1,
.topBox2,
.topBox3 {
    top: 10px;
    width: 33%;
    justify-content: end;
    width: 0px;
}

.bottomBox1,
.bottomBox2,
.bottomBox3 {
    top: -10px;
    width: 25%;
}

.leftBox1,
.rightBox1 {
    width: 0px;
    top: -60px;
    height: 7rem;
}

.rightBox1 {
    top: -120px;
    height: 10rem !important;
    left: 50px;
    margin-bottom: 5px;
}

.topBox1 {
    left: 11%;
}

.topBox2 {
    left: 78%;
}

.topBox3 {
    left: 102%;
}

.bottomBox1 {
    left: -5%;
}

.bottomBox3 {
    left: 5%;
}

.bottomBox4 {
    top: -8.95rem;
    width: 0px;
}

.column {
    display: flex;
    flex-direction: column;
}

.indTextTop,
.indTextBottom,
.indTextLeft,
.indTextRight {
    display: flex;
    font-family: var(--brandBodyFontFamily);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
}

.lineTop,
.lineBottom,
.lineLeft,
.lineRight {
    position: relative;
    height: 4rem;
    border-left: 2px solid #4A1D9C;
}

.lineLeft {
    height: 3rem;
}

.lineLeftDotted,
.lineRightDotted {
    width: 6rem;
    position: relative;
    border-bottom: 2px dashed #4A1D9C;
    top: -9px;
}

.lineLeftDotted {
    left: -52px;
}

.dotTop,
.dotBottom,
.dotLeft,
.dotRight {
    height: 8px;
    width: 8px;
    background-color: #D5A3FF;
    border-radius: 50%;
    border: 4px solid #4A1D9C;
    left: 50%;
}

.dotBottom {
    border: 4px solid #A273E1;
}

.bottomBox1:hover .dotBottom,
.bottomBox2:hover .dotBottom,
.bottomBox3:hover .dotBottom,
.bottomBox4:hover .dotBottom,
.topBox1:hover .dotTop,
.topBox2:hover .dotTop,
.topBox3:hover .dotTop,
.rightBox1:hover .dotRight,
.leftBox1:hover .dotLeft {
    height: 10px;
    width: 10px;
}

.bottomBox1:hover .lineBottom,
.bottomBox2:hover .lineBottom,
.bottomBox3:hover .lineBottom,
.bottomBox4:hover .lineBottom,
.topBox1:hover .lineTop,
.topBox2:hover .lineTop,
.topBox3:hover .lineTop {
    height: calc(4rem - 2px);
}

.rightBox1:hover .lineRightDotted,
.leftBox1:hover .lineLeftDotted {
    top: -11px;
}

.rightBox1:hover .dotRight {
    position: relative;
    top: -1px !important;
}

.rightBox1:hover {
    margin-bottom: 3px;
}

.topBoxContainer,
.bottomBoxContainer {
    display: flex;
    position: relative;
    width: 81%;
}

.middleWrapper {
    display: flex;
    flex-direction: row;
    height: 41px;
}

@media screen and (max-width: 1430px) {
    .indTextTop {
        width: 100px !important;
    }
}

@media screen and (max-width: 815px) {

    .processContainer,
    .title2,
    .underText {
        display: none;
    }

    .body {
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media screen and (max-width: 480px) {
    .container {
        width: 100% !important;
        margin: 0 auto !important;
    }

    .top {
        position: inherit;
        left: 0% !important;
    }
}

.underText {
    font-family: var(--brandBodyFontFamily);
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 14px;
    align-self: center;
    margin-top: 5px;
}