.container {
    width: 80%;
    margin: 0 auto;
    margin-top: 2%;
}

@media screen and (max-width: 480px) {
    .container {
        overflow-x: scroll;
        width: 95%;
    }
}