.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background-color: #FFD599;
    position: fixed;
    bottom: 85px;
    right: 10px;
    border-radius: 30px;
    cursor: pointer;
}

.icon {
    height: 24px;
    width: 24px;
    z-index: 1000;
}

.textContainer {
    position: fixed;
    right: 85px;
    bottom: 85px;
    display: flex;
    flex-direction: column;
    background-color: #FFF3E0;
    padding: 16px 24px 16px 24px;
    border-radius: 12px;
    font-family: var(--brandBodyFontFamily);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 25%;
}

.contactTextContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 400;
    line-height: 20px;
    padding-top: 14px;
    color: rgba(0, 0, 0, 0.6);
    white-space: nowrap;
}

.mailTo, .mailTo:visited {
    text-decoration: none;
    margin-left: 3px;
    margin-right: 3px;
}

@media screen and (max-width: 600px) {
    .textContainer {
        width: 60%;
    }
}

@media screen and (max-width: 480px) {
    .contactTextContainer {
        white-space: normal !important;
        word-break: break-all !important;
        word-wrap: break-word !important;
    }
}

