/* ROOT COLORS ARE IMPORTED FROM CONFIG FOLDER, SO THEY ARE IN ONE PLACE */
@import url("./config/brandStyle.css");

/* IMPORT FONTS */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&family=Source+Sans+Pro:wght@400;600&display=swap');

body {
  margin: 0;
  background-color: var(--brandFormBackground);
  height: 100vh;
  background-color: #FFFFFF;
}
/* 
//UNCOMMENT THIS IF YOU WANT TO HIDE RECAPTCHA BADGE
.grecaptcha-badge { visibility: hidden; }
*/
@media screen and (max-width: 480px) {
  .MuiStepConnector-line {
      display: none !important;
  }
}

.Mui-active {
  color: #4A1D9C !important;
}

.Mui-completed {
  color: #A273E1 !important;
}

.MuiFormLabel-root {
  white-space: normal !important;
}


/*
.MuiTooltip-tooltip {
  position: relative !important;
  color: black !important;
  font-size: 16px !important;
  font-family: var(--brandBodyFontFamily);
  line-height: 24px;
  background-color:  rgba(239, 239, 244, 0.1) !important;
  box-shadow: rgba(16, 24, 40, 0.1) 0px 4px 12px, rgba(129, 80, 190, 0.1) 0px 4px 12px;
  z-index: 1500;
}

.MuiTooltip-arrow {
  color: rgb(239, 239, 244) !important;
}

*/