.container {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 3%;
}

.title {
    font-size: var(--brandTitleFontSize);
    color: var(--brandTitleFontColor);
    font-family: var(--brandTitleFontFamily);
    font-weight: var(--brandTitleFontWeight);
}

.body {
    margin-top: 1.5%;
    font-size: 20px;
    color: var(--brandBodyFontColor);
    font-family: var(--brandBodyFontFamily);
    font-style: var(--brandBodyFontStyle);
    line-height: 24px;
}

.packageContainer {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3% 1% 0% 1%;
}

@media screen and (max-width: 1180px) {
    .packageContainer {
        flex-direction: column;
    }

    .package {
        margin-left: 0px;
    }

    .underText {
        align-self: flex-start !important;
        margin-left: 2%;
    }
}

@media screen and (max-width: 480px) {
    .container {
        width: 100% !important;
        margin: 0 auto !important;
    }

    .packageContainer {
        width: 95%;
        margin: 0 auto;
    }

    .package {
        width: 90% !important;
        padding: 10px !important;
        margin-top: 5%;
    }

    .underText {
        margin-top: 5%;
    }
}

.package {
    display: flex;
    flex-direction: column;
    width: 20rem;
    padding: 33px;
    background-color: #FAFAFB;
    box-shadow: rgba(16, 24, 40, 0.1) 0px 4px 12px, rgba(129, 80, 190, 0.1) 0px 4px 12px;
    border-radius: 15px;
    margin-bottom: 3%;
    margin-left: 1%;
}

.packageTitle {
    font-size: 24px;
    color: var(--brandTitleFontColor);
    font-family: var(--brandTitleFontFamily);
    font-style: var(--brandTitleFontStyle);
}

.packageBody {
    margin-top: 1.5%;
    font-size: var(--brandBodyFontSize);
    color: var(--brandBodyFontColor);
    font-family: var(--brandBodyFontFamily);
    font-style: var(--brandBodyFontStyle);
    margin-top: 15px;
    flex-grow: 1;
    line-height: 24px;
}

.packagePrice {
    display: flex;
    flex-direction: row;
    font-size: 36px;
    align-items: baseline;
    color: var(--brandBodyFontColor);
    font-family: var(--brandTitleFontFamily);
    font-style: var(--brandTitleFontStyle);
    margin-top: 15px;
}

.currency {
    font-size: 24px;
    color: var(--brandBodyFontColor);
    font-family: var(--brandTitleFontFamily);
    font-style: var(--brandTitleFontStyle);
    margin-right: 5px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15%;
    background-color: var(--brandButtonBackground);
    border-radius: 20px;
    height: 42px;
    width: 90%;
    color: #FFFFFF;
    font-family: var(--brandTitleFontFamily);
    font-style: var(--brandTitleFontStyle);
    align-self: center;
    cursor: pointer;
    transition: 0.3s;
}

.button:hover {
    background-color: var(--brandButtonBackgroundHovered);
}

.underText {
    font-family: var(--brandBodyFontFamily);
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 14px;
    align-self: center;
}

@media screen and (max-width: 815px) {
    .body {
        margin-top: 5%;
    }
}