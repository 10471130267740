.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1440px;
}

.logoContainer img {
    width: 133px;
    margin: 15px 0px 5px 0px;
}

@media screen and (max-width: 480px) {
    .container {
        width: 95%;
        margin-bottom: 8%;
    }
}